import { useIntl } from "react-intl";
import { TranslationItem } from "../models/commonModel";

export default function useLocalisation(): {
  formatMessage: (translationItem: TranslationItem) => string;
} {
  const intl = useIntl();
  const formatMessage = (translationItem: TranslationItem) => {   
    return intl.formatMessage({
      defaultMessage: translationItem.defaultMessage,
      description: translationItem.description,
      id: translationItem.id,
    });
  };
  return { formatMessage: formatMessage };
}
